.transfer .list {
  padding: 20px 10px;
}
.transfer .list .title {
  margin-bottom: 18px;
}
.transfer .list .el-table__row {
  background-color: #f2f7fd;
}
.transfer .leftList {
  width: 30%;
}
.transfer .leftList .tableList {
  background-color: #f2f7fd;
  height: 100%;
}
.transfer .leftList .tableList .el-table {
  background-color: #f2f7fd;
}
.transfer .button_group {
  overflow: hidden;
  max-height: 400px;
}
.transfer .tool_box {
  padding: 0 10px 0 30px;
  align-items: flex-start;
}
.transfer .tool_box > div {
  margin: 0 10px 30px;
}
.transfer .tool_box .tool_button {
  width: 9.375rem;
  margin-right: 10px;
}
.transfer .tool_box .el-button + .el-button {
  margin: 0;
}
.transfer .tool_box .delete_icon {
  color: #ccc;
}
.transfer .tool_box .delete_icon:hover {
  color: red;
}
.transfer .rightList {
  width: 35%;
}
.transfer .rightList .group .group_item {
  margin-bottom: 15px;
  background-color: #f2f7fd;
}
.transfer .rightList .group .group_item .el-table {
  background-color: #f2f7fd;
}
.transfer .rightList .group .group_item > span {
  text-align: center;
  padding: 5px;
  font-size: 1rem;
  font-weight: 600;
}
.transfer .rightList .group > div.group_item:last-child {
  margin-bottom: 0;
}
.transfer .btn_box {
  padding: 20px 0 0;
}
.transfer .el-icon-close {
  cursor: pointer;
}
