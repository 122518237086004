




































































































































































































































































































































































































































.transfer {
  .list {
    padding: 20px 10px;
    .title {
      margin-bottom: 18px;
    }
    .el-table__row {
      background-color: #f2f7fd;
    }
  }
  .leftList {
    width: 30%;
    .tableList {
      background-color: #f2f7fd;
      height: 100%;
      .el-table {
        background-color: #f2f7fd;
      }
    }
  }
  .button_group {
    overflow: hidden;
    max-height: 400px;
  }
  .tool_box {
    padding: 0 10px 0 30px;
    align-items: flex-start;
    > div {
      margin: 0 10px 30px;
    }
    .tool_button {
      width: 9.375rem;
      margin-right: 10px;
    }
    .el-button + .el-button {
      margin: 0;
    }
    .delete_icon {
      color: #ccc;
    }
    .delete_icon:hover {
      color: red;
    }
  }
  .rightList {
    width: 35%;
    .group {
      .group_item {
        margin-bottom: 15px;
        background-color: #f2f7fd;
        .el-table {
          background-color: #f2f7fd;
        }
        > span {
          text-align: center;
          padding: 5px;
          font-size: 1rem;
          font-weight: 600;
        }
      }
      > div.group_item:last-child {
        margin-bottom: 0;
      }
    }
  }
  .btn_box {
    padding: 20px 0 0;
  }
  .el-icon-close {
    cursor: pointer;
  }
}
